
export const BASE_URL = "https://anubhuthiai.com";
// export const BASE_URL = "http://localhost:7006";
// export const BASE_URL = "http://192.168.85.58:7006";
// export const BASE_URL = "http://192.168.85.58:7006";
// export const BASE_URL = "http://127.0.0.1:7006";

export const APPSERVICE = {

  //login
  LOGIN: `${BASE_URL}/api/anubhuthi/authlog`,
  LOGOUT: `${BASE_URL}/api/anubhuthi/authout`,
  AUTHLOG_CREATE: `${BASE_URL}/api/anubhuthi/auth/new`,
  AUTHLOG_USER_ID: `${BASE_URL}/api/authloginuser`,

  //user
  USERS: `${BASE_URL}/api/anubhuthi/authusers`,
  USER_CREATE: `${BASE_URL}/api/anubhuthi/auth/new`,
  USER_SINGLE: `${BASE_URL}/apianubhuthi//auth`,

  //questions
  QUESTIONS: `${BASE_URL}/api/questions`,
  QUESTIONSPRESENTDATA: `${BASE_URL}/api/questionspresentdata`,
  QUESTIONS_CREATE: `${BASE_URL}/api/question/new`,
  QUESTIONS_SINGLE: `${BASE_URL}/api/question`,


  //  registers
  REGISTERS: `${BASE_URL}/api/registers`,
  REGISTERSREPORT: `${BASE_URL}/api/registerssattamanram`,
  REGISTERSPRESENTDATA: `${BASE_URL}/api/registerspresentdata`,
  REGISTERSID: `${BASE_URL}/api/registersid`,
  REGISTERS_SINGLE: `${BASE_URL}/api/register`,
  REGISTER_CREATE: `${BASE_URL}/api/register/new`,

  REGISTERUSERLASTINDEX: `${BASE_URL}/api/registeruserslastdata`,

  REGISTER_CREATE_USERMAIL: `${BASE_URL}/api/register/usermail`,
  REGISTER_CREATE_ADMINMAIL: `${BASE_URL}/api/register/adminmail`,

  //image uploads
  UPLOADS: `${BASE_URL}/api/uploads`,
  UPLOAD_CREATE: `${BASE_URL}/api/upload/new`,
  UPLOAD_SINGLE: `${BASE_URL}/api/upload`,

  // samayam image uploads
  SAMAYAM_UPLOADS: `${BASE_URL}/api/samayamuploads`,
  SAMAYAMUPLOAD_CREATE: `${BASE_URL}/api/samayamupload/new`,
  SAMAYAMUPLOAD_SINGLE: `${BASE_URL}/api/samayamupload`,

  // mahan image uploads
  MAHAN_UPLOADS: `${BASE_URL}/api/mahanuploads`,
  MAHANUPLOAD_CREATE: `${BASE_URL}/api/mahanupload/new`,
  MAHANUPLOAD_SINGLE: `${BASE_URL}/api/mahanupload`,

  // thayar image uploads
  THAYAR_UPLOADS: `${BASE_URL}/api/thayaruploads`,
  THAYARUPLOAD_CREATE: `${BASE_URL}/api/thayarupload/new`,
  THAYARUPLOAD_SINGLE: `${BASE_URL}/api/thayarupload`,

  // awarness image uploads
  AWARNESS_UPLOADS: `${BASE_URL}/api/awarnessuploads`,
  AWARNESSUPLOAD_CREATE: `${BASE_URL}/api/awarnessupload/new`,
  AWARNESSUPLOAD_SINGLE: `${BASE_URL}/api/awarnessupload`,

  // duty image uploads
  DUTY_UPLOADS: `${BASE_URL}/api/dutyuploads`,
  DUTYUPLOAD_CREATE: `${BASE_URL}/api/dutyupload/new`,
  DUTYUPLOAD_SINGLE: `${BASE_URL}/api/dutyupload`,
  //  regionaldirector
  REGIONALDIRECTOR: `${BASE_URL}/api/regionaldirectors`,
  REGIONALREPORT: `${BASE_URL}/api/regionaldirectorsattamanram`,
  REGIONALDIRECTORDATA: `${BASE_URL}/api/regionaldirectorpresentdata`,
  REGIONALID: `${BASE_URL}/api/regionaldirectorsid`,
  REGIONALDIRECTOR_SINGLE: `${BASE_URL}/api/regionaldirector`,
  REGIONAL_CREATE: `${BASE_URL}/api/regionaldirector/new`,

  // Executive
  EXECUTIVE: `${BASE_URL}/api/executives`,
  EXECUTIVEREPORT: `${BASE_URL}/api/executivesattamanram`,
  EXECUTIVEREPORTDATA: `${BASE_URL}/api/executivepresentdata`,
  EXECUTIVEID: `${BASE_URL}/api/executiveid`,
  EXECUTIVE_SINGLE: `${BASE_URL}/api/executive`,
  EXECUTIVE_CREATE: `${BASE_URL}/api/executive/new`,

  //Executive Head...
  EXECUTIVEHEAD: `${BASE_URL}/api/executiveheads`,
  EXECUTIVEHEADREPORT: `${BASE_URL}/api/executiveheadsattamanram`,
  EXECUTIVEHEADREPORTDATA: `${BASE_URL}/api/executiveheadpresentdata`,
  EXECUTIVEHEADID: `${BASE_URL}/api/executiveheadid`,
  EXECUTIVEHEAD_SINGLE: `${BASE_URL}/api/executivehead`,
  EXECUTIVEHEAD_CREATE: `${BASE_URL}/api/executivehead/new`,

  //PAO...
  PAO: `${BASE_URL}/api/paos`,
  PAOREPORT: `${BASE_URL}/api/paosattamanram`,
  PAOREPORTDATA: `${BASE_URL}/api/paopresentdata`,
  PAOID: `${BASE_URL}/api/paosid`,
  PAO_SINGLE: `${BASE_URL}/api/pao`,
  PAO_CREATE: `${BASE_URL}/api/pao/new`,

  //payment
  RAZORPAY_VERIFICATION_KEY: `${BASE_URL}/api/getrazorpaykey`,
  RAZORPAY_CHECKOUT: `${BASE_URL}/api/checkoutrazorpay`,
  RAZORPAY_VERIFICATION: `${BASE_URL}/api/verificationrazorpay`,

  //settings
  GETSINGLESETTINGS: `${BASE_URL}/api/authbusisetng`,
  SETTINGS: `${BASE_URL}/api/busisetngs`,
  SETTING_CREATE: `${BASE_URL}/api/busisetng/new`,
  SETTING_SINGLE: `${BASE_URL}/api/busisetng`,

  ENQUIRES: `${BASE_URL}/api/todayenquiries`,
  ENQUIRY_FORMS: `${BASE_URL}/api/enquiryforms`,
  NEW_ENQUIRY_FORM: `${BASE_URL}/api/enquiryform/new`,
  SINGLE_ENQUIRY_FORM: `${BASE_URL}/api/enquiryform`,

  REGISTERS_SINGLEBYUSERNAME: `${BASE_URL}/api/registerationdetailsbypassno`,


  GETALLUPLOADEDVIDEO: `${BASE_URL}/api/videoupload`,
  GETALLUPLOADEDVIDEORESTRICTED: `${BASE_URL}/api/videouploadrestricted`,
  ADDUPLOADVIDEO: `${BASE_URL}/api/videoupload/new`,
  SINGLE_UPLOADVIDEO: `${BASE_URL}/api/videoupload`,
  SINGLE_UPLOADVIDEOBYCOMMONID: `${BASE_URL}/api/videouploadcommonid`,
  SINGLEVIDEODELETE: `${BASE_URL}/api/videodelete`,

  CAMPAIGN: `${BASE_URL}/api/campaign`,
  CREATE_CAMPAIGN: `${BASE_URL}/api/campaign/new`,
  SINGLE_CAMPAIGN: `${BASE_URL}/api/campaign`,



  //newly added 22.11.2024
  REVIEW: `${BASE_URL}/api/reviewform`,
  CREATE_REVIEW: `${BASE_URL}/api/reviewform/new`,
  SINGLE_REVIEW: `${BASE_URL}/api/reviewform`,


  NOTELIGIBLEMAIL: `${BASE_URL}/api/noteligiblemail`,

  SINGLE_POINTS_SETTINGS: `${BASE_URL}/api/singlepointssetting`,
  CREATE_POINTS_SETTINGS: `${BASE_URL}/api/createpointssettings`,
  GET_POINTS_SETTINGS: `${BASE_URL}/api/getpointssettings`,
}